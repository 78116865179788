import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const FooterWrapper = styled.footer`
  width: 100%;
  padding: 130px 0 40px;
  background-color: ${themeGet("colors.black")};
  z-index: 0;
  @media (max-width: 480px) {
    padding: 100px 0 0;
  }

  .col {
    width: 22%;
    margin-right: 32px;
    margin-bottom: 30px;
    @media (max-width: 990px) {
      width: 100%;
      margin-right: 0px;
    }
  }

  .col-two {
    position: relative;
    .col:nth-child(4) {
      margin-right: 0px !important;
    }
  }

  .col-one {
    position: relstive;
    .logo {
      width: 160px;
      margin-bottom: 60px;
      @media (max-width: 990px) {
        margin-bottom: 32px;
      }
    }
  }

  .widget_title {
    color: ${themeGet("colors.white")};
    font-size: 24px;
    margin-bottom: 24px;
  }

  .text {
    color: ${themeGet("colors.white")};
    font-size: 18px;
    margin-bottom: 9px;
  }

  .backToTop {
    position: absolute;
    bottom: 30px;
    right: 0;

    img {
      width: 60px;
      @media (max-width: 990px) {
        width: 50px;
      }
    }
    /* margin-left: auto;
    margin-right: 0px;
    margin-top: -100px;
    cursor: pointer; */
    /* @media only screen and (max-width: 480px) {
      margin-right: 10px;
      margin-top: -100px;
      width: 50px;
    } */
  }

  .pagesLinks {
    font-weight: 600;
    color: ${themeGet("colors.white")};
    font-size: 16px;
    margin-bottom: 24px;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: ${themeGet("colors.mainBlue")};
    }
  }

  .copyright {
    width: 100%;
    margin-top: 80px;
    padding-top: 24px;
    margin-bottom: 40px;
    padding-bottom: 0px;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${themeGet("colors.white")};
    @media only screen and (max-width: 480px) {
      justify-content: center;
      margin-top: 10px;
      padding-top: 24px;
      flex-direction: column;
    }

    .links {
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        margin-right: 10px;
      }

      .goodfirms {
        a {
          margin-right: 0px;
        }
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
      color: ${themeGet("colors.white")};
      margin: 0;
      @media only screen and (max-width: 480px) {
        font-size: 14px;
      }
    }
  }
`

export const List = styled.ul`
  width: 100%;
`

export const ListItem = styled.li`
  margin-bottom: 8px;
  max-width: 250px;
  margin-right: 16px;
  a {
    font-weight: 300;
    color: ${themeGet("colors.white")};
    font-size: 16px;

    line-height: 27px;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: ${themeGet("colors.mainBlue")};
    }
  }
  @media (max-width: 990px) {
    max-width: 100%;
  }
`

export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 68px;
  @media (max-width: 990px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  li {
    margin-right: 17px;
    background-color: ${themeGet("colors.white")};
    border-radius: 50%;
    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: ${themeGet("colors.black")};
      position: relative;
      padding: 5px;
      transition: all 0.3s ease;
      i {
        position: relative;
        z-index: 1;

        svg {
          width: 20px;
          height: auto;
        }
      }

      &:hover {
        color: ${themeGet("colors.mainBlue")};
      }
    }
  }
`

export const Careers = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  .careers {
    margin-right: 20px;
  }
  .hireLink {
    font-weight: 600;
    color: ${themeGet("colors.mainBlue")};
    font-size: 12px;
    border: 1px solid ${themeGet("colors.mainBlue")};
    padding: 4px 15px;
  }
`

export default FooterWrapper
