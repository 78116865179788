import PropTypes from "prop-types"
import React from "react"
import Scrollspy from "react-scrollspy"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

import Submenu from "./submenu"

const ScrollSpyMenu = ({ className, menuItems, drawerClose, ...props }) => {
  const data = useStaticQuery(graphql`
    query ServicesSubMenu {
      devopsSub: allPrismicServicesPage(
        filter: { data: { category: { eq: "devops" } } }
      ) {
        edges {
          node {
            data {
              title {
                text
              }
              category
            }
            uid
          }
        }
      }
      cloudSub: allPrismicServicesPage(
        filter: { data: { category: { eq: "cloud" } } }
      ) {
        edges {
          node {
            data {
              title {
                text
              }
              category
            }
            uid
          }
        }
      }
      devOpsSubCons: allPrismicConsulting(
        filter: { data: { category: { eq: "devops" } } }
      ) {
        edges {
          node {
            data {
              title {
                text
              }
              category
            }
            uid
          }
        }
      }
      cloudSubCons: allPrismicConsulting(
        filter: { data: { category: { eq: "cloud" } } }
      ) {
        edges {
          node {
            data {
              title {
                text
              }
              category
            }
            uid
          }
        }
      }
    }
  `)

  // const { dispatch } = useContext(DrawerContext)
  // empty array for scrollspy items
  const scrollItems = []

  // convert menu path to scrollspy items
  menuItems.forEach(item => {
    scrollItems.push(item.path.slice(1))
  })

  // Add all classs to an array
  const addAllClasses = ["scrollspy__menu"]

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  // Close drawer when click on menu item
  // const toggleDrawer = () => {
  //   dispatch({
  //     type: "TOGGLE",
  //   })
  // }

  return (
    <Scrollspy
      items={scrollItems}
      className={addAllClasses.join(" ")}
      drawerClose={drawerClose}
      {...props}
    >
      {menuItems.map((menu, index) => (
        <li key={`menu-item-${index}`}>
          {menu.staticLink ? (
            <Link
              to={menu.path}
              activeStyle={{
                color: "#21AAD4",
              }}
            >
              {menu.label}
            </Link>
          ) : (
            <>
              {drawerClose ? (
                <Link
                  to={menu.path}
                  activeStyle={{
                    color: "#21AAD4",
                  }}
                >
                  {menu.label}
                </Link>
              ) : (
                <>
                  <Link
                    to={menu.path}
                    activeStyle={{
                      color: "#fff",
                      borderBottom: "2px solid #fff",
                      padding: "24px 5px",
                    }}
                  >
                    {menu.label}
                  </Link>
                  {menu.label === "SERVICES" && (
                    <div className="submenu_wrapper">
                      <div className="submenu_content">
                        <Submenu content={data} />
                      </div>
                    </div>
                  )}
                  {menu.label === "CONSULTING" && (
                    <div className="submenu_wrapper">
                      <div className="submenu_content">
                        <Submenu content={data} consulting />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </li>
      ))}
    </Scrollspy>
  )
}

ScrollSpyMenu.propTypes = {
  /** className of the ScrollSpyMenu. */
  className: PropTypes.string,

  /** menuItems is an array of object prop which contain your menu
   * data.
   */
  menuItems: PropTypes.array.isRequired,

  /** Class name that apply to the navigation element paired with the content element in viewport. */
  currentClassName: PropTypes.string,

  /** Class name that apply to the navigation elements that have been scrolled past [optional]. */
  scrolledPastClassName: PropTypes.string,

  /** HTML tag for Scrollspy component if you want to use other than <ul/> [optional]. */
  componentTag: PropTypes.string,

  /** Style attribute to be passed to the generated <ul/> element [optional]. */
  style: PropTypes.object,

  /** Offset value that adjusts to determine the elements are in the viewport [optional]. */
  offset: PropTypes.number,

  /** Name of the element of scrollable container that can be used with querySelector [optional]. */
  rootEl: PropTypes.string,

  /**
   * Function to be executed when the active item has been updated [optional].
   */
  onUpdate: PropTypes.func,
}

ScrollSpyMenu.defaultProps = {
  componentTag: "ul",
  currentClassName: "is-current",
}

export default ScrollSpyMenu
