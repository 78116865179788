import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const ContactBannerWrapper = styled.div`
  background-color: ${themeGet("colors.white")};
  position: relative;
  padding: 60px 160px 120px;
  width: 1504px;
  margin-top: 70px;
  @media (max-width: 1560px) {
    width: 1200px;
    padding: 60px 80px 120px;
  }
  @media (max-width: 1220px) {
    width: 700px;
    padding: 60px 80px 60px;
    margin-top: 70px;
  }
  @media (max-width: 760px) {
    width: 480px;
    padding: 60px 80px 60px;
  }
  @media (max-width: 480px) {
    width: 90%;
    padding: 10px 30px 10px;
    margin: 100px auto 0;
    max-height: 100vh;
  }

  .form {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 10%;
    @media (max-width: 1220px) {
      grid-template-columns: 100%;
      grid-gap: 0px;
    }
  }

  .close {
    position: absolute;
    top: 70px;
    right: 70px;
    cursor: pointer;
    @media (max-width: 1560px) {
      right: 80px;
    }
    @media (max-width: 1220px) {
      right: 35px;
      top: 80px;
    }
    @media (max-width: 760px) {
      right: 35px;
      top: 80px;
    }
    @media (max-width: 480px) {
      right: 10px;
      top: 10px;
    }
  }

  .reusecore__button {
    width: 50%;
    background-color: ${themeGet("colors.mainBlue")};
    color: ${themeGet("colors.white")};
    font-size: 20px;
    border-radius: 0px;
    padding: 20px 21px;
    text-transform: capitalize;
    margin-top: 10px;
    width: 100%;
    @media (max-width: 480px) {
      width: 100%;
    }
    &:hover {
      background-color: ${themeGet("colors.mainBlueHover")};
    }
  }

  h1 {
    margin-bottom: 40px;
    font-size: 52px;
    @media (max-width: 990px) {
      font-size: 40px;
    }
    @media (max-width: 480px) {
      margin-bottom: 20px;
      font-size: 36px;
    }
    span {
      color: ${themeGet("colors.mainBlue")};
    }
  }
`

export default ContactBannerWrapper
