import CookieConsent, { Cookies } from "react-cookie-consent"
import React, { useState } from "react"

import CloseIcon from "../../common/assets/image/alpacked/CookieNotice/close.svg"
import styles from "./styles.module.css"

const CookieNotice = () => {
  const [toggle, setToggle] = useState(true)

  const setCookie = () => {
    Cookies.set("gatsby-gdpr-google-analytics", "true", { expires: 365 })
    Cookies.set("gatsby-gdpr-facebook-pixel", "true", { expires: 365 })
  }

  return (
    <>
      {toggle && (
        <CookieConsent
          location="bottom"
          buttonText="OK, DON'T SHOW AGAIN"
          declineButtonText="Decline"
          disableStyles={true}
          flipButtons={true}
          onAccept={setCookie}
          containerClasses={styles.container}
          buttonClasses={styles.buttonStyle}
          contentClasses={styles.contentStyle}
        >
          <img
            loading="lazy"
            aria-hidden="true"
            src={CloseIcon}
            alt="close icon"
            className={styles.close}
            onClick={() => setToggle(false)}
          />
          <div>We use cookies to provide the best site experience.</div>
        </CookieConsent>
      )}
    </>
  )
}

export default CookieNotice
