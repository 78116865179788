import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  z-index: 999;
  padding: 72px 0 72px;

  @keyframes ScaleInUp {
    from {
      opacity: 0;
      transform: translateY(30px) scale(0.97);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  .container {
    padding: 0px 30px;
  }

  .update-screen-tab {
    border: 0;
    overflow: initial;
    .rc-tabs-ink-bar {
      display: none !important;
    }
    .rc-tabs-bar {
      border: 0;
    }
    .rc-tabs-nav-container {
      margin-bottom: 45px;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: #242424;
        display: block;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
      &:not(.rc-tabs-nav-container-scrolling) {
        .rc-tabs-nav-scroll {
          width: 100%;
          text-align: center;
          .rc-tabs-nav {
            /* float: none; */
            text-align: left;
            display: block;
            .rc-tabs-tab {
              display: inline-block;
              float: none;
            }
          }
        }
      }
      .rc-tabs-tab {
        font-size: 22px;
        font-weight: 300;
        color: #7a7a7a;
        font-weight: 300;
        padding: 0 0 25px 0;
        text-align: center;
        margin-right: 52px;
        transition: 0.25s ease-in-out;
        &:hover {
          color: ${themeGet("colors.mainBlue")};
        }
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          display: block;
          @media (max-width: 767px) {
            display: none;
          }
        }
        &:after {
          background: ${themeGet("colors.white")};
          transform: scaleX(0);
          transform-origin: right center 0;
          transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        }
        &.rc-tabs-tab-active {
          color: ${themeGet("colors.white")};
          &:after {
            transform: scaleX(1);
            transform-origin: left center 0;
            transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
          }
        }
        > div {
          margin-right: 8px;
        }
        @media (max-width: 1199px) {
          font-size: 22px;
          padding: 0 0 20px 0;
          min-width: 170px;
        }
        @media (max-width: 990px) {
          min-width: auto;
          padding: 0 20px 15px 20px;
        }
        @media (max-width: 767px) {
          font-size: 22px;
          svg {
            width: 20px;
          }
        }
      }
    }
    .rc-tabs-content {
      .rc-tabs-tabpane {
        border-radius: 10px;
        overflow: hidden;
        &.rc-tabs-tabpane-active {
          animation: 0.7s ScaleInUp;
        }
        > img {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }
`

export const SubmenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1337px) {
    grid-template-columns: 1fr;
  }

  a {
    padding: 0px 0px 14px 0px !important;
  }

  p {
    color: white;
    font-size: 18px;
    font-weight: 300;
    transition: all 0.3s ease;
    &:hover {
      color: ${themeGet("colors.mainBlue")};
    }
  }

  .see_all_container {
    display: flex;
    align-items: center;
    &:hover svg {
      color: ${themeGet("colors.mainBlue")};
    }
    svg {
      color: #fff;
      transition: all 0.3s ease;
    }
  }

  .see_all {
    font-size: 18px;
    font-weight: 700;
    margin-right: 30px;
    margin-bottom: 0px;
  }
`

export default SectionWrapper
