import React from "react"

import CloseIcon from "../../common/assets/image/alpacked/home/close.svg"
import ContactBannerWrapper from "./modal.style"
import Form from "./form"

const ContactModal = ({ closeModal, pathname }) => {
  return (
    <ContactBannerWrapper>
      <img
        aria-hidden="true"
        src={CloseIcon}
        alt="close modal"
        onClick={closeModal}
        className="close"
      />
      <h1>
        Quote <span>request</span>
      </h1>
      <Form pathname={pathname} closeModal={closeModal} />
    </ContactBannerWrapper>
  )
}

export default ContactModal
