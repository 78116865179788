import { AlpackedWrapper, GlobalStyle } from "../../containers/alpacked.style"
import React, { Fragment, useEffect, useState } from "react"

import BackToTop from "../../common/assets/image/alpacked/backToTop.svg"
import CookieNotice from "../../containers/CookieNotice"
import { DrawerProvider } from "../../contexts/DrawerContext"
import Footer from "../../containers/Footer"
import ModalEBook from "../ModalEbook"
import Navbar from "../../containers/Navbar"
import { ResetCSS } from "../../common/assets/css/style"
import ScrollUpButton from "react-scroll-up-button"
import SimpleReactLightbox from "simple-react-lightbox"
import Sticky from "react-stickynode"
import { ThemeProvider } from "styled-components"
import { VisibilityContext } from "../../contexts/VisibilityContext"
import { alpackedTheme } from "../../theme"

const Layout = ({ children, pathname }) => {
  const [visible, setVisible] = useState(true)
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return (
    <VisibilityContext.Provider value={{ visible, setVisible }}>
      <SimpleReactLightbox>
        <ThemeProvider theme={alpackedTheme}>
          <Fragment>
            <ResetCSS />
            <GlobalStyle />

            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar pathname={pathname} />
              </DrawerProvider>
            </Sticky>

            <AlpackedWrapper>
              <ModalEBook />
              {children}
              {visible && <Footer />}
              {hasMounted && <CookieNotice />}
            </AlpackedWrapper>

            <ScrollUpButton
              ContainerClassName="scroll_to_top"
              TransitionClassName="scroll_to_top_transition"
              ShowAtPosition={600}
            >
              <img loading="lazy" src={BackToTop} alt="back to top" />
            </ScrollUpButton>
          </Fragment>
        </ThemeProvider>
      </SimpleReactLightbox>
    </VisibilityContext.Provider>
  )
}

export default Layout
