import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export const Section = styled.div`
  position: relative;
  display: flex;
  @media only screen and (max-width: 990px) {
    flex-direction: column;
  }

  .close {
    position: absolute;
    top: -16px;
    right: 0px;
    cursor: pointer;
    width: 15px;
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 0px 0px 40px;
  width: 50%;
  @media only screen and (max-width: 990px) {
    width: 100%;
    padding: 0px;
  }

  .list-items {
    list-style: none;

    li::before {
      content: "• ";
      color: ${themeGet("colors.mainBlue")};
      margin-right: 6px;
    }
    li {
      margin-bottom: 12px;
      font-size: 15px;
      line-height: 20px;
      color: #000;
      list-style-position: inside;
      padding-left: 17px;
      text-indent: -17px;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }

  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 12px;
    @media only screen and (max-width: 990px) {
      margin: 22px 0px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .details {
    display: flex;
    justify-content: space-between;
  }
  .category {
    font-family: "Open Sans";
    font-weight: 700;
    color: ${themeGet("colors.mainBlue")};
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 16px 0 0;
  }
  .category-details {
    display: flex;
    align-items: center;
  }
  .seperator {
    border-top: 2px solid ${themeGet("colors.mainBlue")};
    width: 40px;
    margin-left: 0;
  }

  .introText {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
    margin-top: -10px;
    @media only screen and (max-width: 990px) {
      margin-top: -16px;
      margin-bottom: 16px;
    }
  }
  .excerptLink {
    color: ${themeGet("colors.black")};
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding: 0px 0px 0px 0px;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
    }
  }

  .reusecore__button {
    width: 100%;
    background-color: ${themeGet("colors.maiBlue")};
    color: ${themeGet("colors.white")};
    font-size: 18px;
    border-radius: 0px;
    padding: 16px 21px;
    text-transform: capitalize;
    margin-top: 25px;

    &:hover {
      background-color: ${themeGet("colors.mainBlueHover")};
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
    }
  }
`

export const Photo = styled.figure`
  width: 50%;
  margin: 0 0 0 0;
  position: relative;
  .image {
    flex-shrink: 0;
    min-width: 100%;
    max-height: 355px;
    opacity: 0.4;
    @media only screen and (max-width: 990px) {
      height: 269px;
    }
  }

  @media only screen and (max-width: 990px) {
    display: none;
  }
`
