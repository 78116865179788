import "./navBar.css"

import { default as Button, default as ButtonSubmit } from "../../components/Button"
import { default as LogoImage, default as LogoImageAlt } from "../../common/assets/image/alpacked/logo-navbar.svg"
import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Box from "../../components/Box"
import ContactModal from "../../containers/Modal"
import Container from "../../components/UI/Container"
import Drawer from "../../components/Drawer"
import { DrawerContext } from "../../contexts/DrawerContext"
import HamburgMenu from "../../components/HamburgMenu"
import Logo from "../../components/UIElements/Logo"
import Modal from "react-modal"
import NavbarWrapper from "../../components/Navbar"
import PropTypes from "prop-types"
import ScrollSpyMenu from "../../components/ScrollSpyMenu"
import { TextField } from "@material-ui/core"

const Navbar = ({
  navbarStyle,
  logoStyle,
  button,
  buttonMobile,
  row,
  menuWrapper,
  pathname,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      alpackedJson {
        MENU_ITEMS {
          label
          path
        }
      }
    }
  `)

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "0px",
    },
  }
  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const { state, dispatch } = useContext(DrawerContext)

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    })
  }

  const openModalMobile = () => {
    openModal()
    toggleHandler()
  }

  return (
    <NavbarWrapper {...navbarStyle} className="alpacked_navbar" id="Navbar">
      <Container width="1440px">
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={LogoImage}
            title="Alpacked.io"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="/"
            logoSrc={LogoImageAlt}
            title="Alpacked.io"
            logoStyle={logoStyle}
            className="logo-alt"
          />

          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.alpackedJson.MENU_ITEMS}
            />
            {/* <ContactModal /> */}
            <div className="navbar_button">
              <Button {...button} title="QUOTE REQUEST" onClick={openModal} />
              {/* This is a hidden form in order for the netlify to recognize it - inside modal
               */}
              <div type="hidden" style={{ display: "none" }}>
                <form
                  name="quote"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <div>
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="quote" />
                    <TextField id="name" label="NAME" name="name" />
                    <TextField id="surname" label="SURNAME" name="surname" />
                    <TextField
                      id="email"
                      label="EMAIL"
                      name="email"
                      type="email"
                    />
                  </div>
                  <div>
                    <TextField id="company" label="COMPANY" name="company" />
                    <TextField
                      id="message"
                      label="MESSAGE"
                      name="message"
                      multiline
                    />
                  </div>
                  <ButtonSubmit type="submit" title="CONTACT US" />
                </form>
              </div>
              <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                style={customStyles}
                onRequestClose={closeModal}
                contentLabel="Contact Modal"
                overlayClassName="Overlay"
              >
                <ContactModal closeModal={closeModal} pathname={pathname} />
              </Modal>
            </div>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.alpackedJson.MENU_ITEMS}
                drawerClose={true}
              />
              <Button
                {...buttonMobile}
                title="QUOTE REQUEST"
                onClick={openModalMobile}
              />
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  )
}

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  buttonMobile: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
}

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: "70px",
    display: "block",
  },
  row: {
    flexBox: true,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  logoStyle: {
    maxWidth: ["79px"],
  },
  button: {
    type: "button",
    fontSize: ["18px"],
    fontWeight: "800",
    borderRadius: "0px",
    pl: "20px",
    pr: "20px",
    pt: "28px",
    pb: "28px",
    minHeight: "auto",
    height: "50px",
  },
  buttonMobile: {
    type: "button",
    fontSize: ["18px"],
    fontWeight: "800",
    borderRadius: "0px",
    pl: "20px",
    pr: "20px",
    minHeight: "auto",
    height: "50px",
  },
  menuWrapper: {
    flexBox: true,
    alignItems: "center",
  },
}

export default Navbar
