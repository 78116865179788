import { Content, Photo, Section } from "./modal.style"
import React, { memo, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import ButtonSubmit from "../../components/Button"
import CloseIcon from "../../common/assets/image/alpacked/home/close.svg"
import Cookies from "js-cookie"
import Heading from "../../components/Heading"
import { Link } from "gatsby"
import Modal from "react-modal"
import Text from "../../components/Text"

const ModalEBook = () => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const afterOpenModal = () => {
    Cookies.set("modalEbook", "true", { expires: 1 })
  }

  useEffect(() => {
    if (!Cookies.get("modalEbook")) {
      setTimeout(() => {
        setIsOpen(true)
      }, 7000)
    }
  }, [])

  const Data = useStaticQuery(graphql`
    query {
      alpackedJson {
        EBOOK {
          info
          subtitle
          text
          items
          image {
            publicURL
          }
          button {
            text
            link
          }
        }
      }
    }
  `)

  const { EBOOK } = Data.alpackedJson

  return (
    <div>
      {modalIsOpen && (
        <Modal
          isOpen={true}
          onAfterOpen={afterOpenModal}
          onRequestClose={() => setIsOpen(false)}
          contentLabel="Ebook Modal"
          overlayClassName="Overlay"
          className="Modal"
        >
          <Section>
            <img
              loading="lazy"
              aria-hidden="true"
              src={CloseIcon}
              alt="close modal"
              onClick={() => setIsOpen(false)}
              className="close"
            />
            <Photo>
              <img
                loading="lazy"
                src={EBOOK.image.publicURL}
                alt="contact details"
              />
            </Photo>
            <Content>
              <div className="details">
                <div className="category-details">
                  <p className="category">{EBOOK.info}</p>
                  <hr className="seperator" />
                </div>
              </div>
              <div>
                <Heading as="h2" content={EBOOK.subtitle} />
                <Text content={EBOOK.text} className="introText" />
                <ul className="list-items">
                  {EBOOK.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              <Link to={`${EBOOK.button.link}`} className="excerptLink">
                <ButtonSubmit title={EBOOK.button.text} />
              </Link>
            </Content>
          </Section>
        </Modal>
      )}
    </div>
  )
}

export default memo(ModalEBook)
