import PropTypes from "prop-types"
import React, { Fragment } from "react"
import { Icon } from "react-icons-kit"
import { Link, graphql, useStaticQuery } from "gatsby"
import { facebook } from "react-icons-kit/fa/facebook"
import { github } from "react-icons-kit/fa/github"
import { linkedin } from "react-icons-kit/fa/linkedin"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Logo from "../../components/UIElements/Logo"
import Text from "../../components/Text"
import FooterWrapper, {
  Careers,
  List,
  ListItem,
  SocialList,
} from "./footer.style"

const socialLinks = [
  {
    id: 1,
    icon: <Icon icon={linkedin} />,
    name: "linkedin",
    link: "https://www.linkedin.com/company/alpacked",
  },
  {
    id: 2,
    icon: <Icon icon={facebook} />,
    name: "facebook",
    link: "https://www.facebook.com/alpacked",
  },
  {
    id: 3,
    icon: <Icon icon={github} />,
    name: "github",
    link: "https://github.com/Alpacked",
  },
]

const Footer = ({ row, col, colOne, colTwo }) => {
  const data = useStaticQuery(graphql`
    query {
      alpackedJson {
        footerData {
          logo {
            publicURL
          }
          # menuWidgets {
          #   id
          #   title
          #   menu {
          #     id
          #     text
          #     link
          #   }
          # }
        }
      }
      allPrismicConsulting {
        edges {
          node {
            id
            uid
            data {
              title {
                text
              }
            }
          }
        }
      }
      allPrismicSingleTechnologiesPage {
        edges {
          node {
            id
            uid
            data {
              title {
                text
              }
            }
          }
        }
      }
      allPrismicServicesPage {
        edges {
          node {
            id
            uid
            data {
              title {
                text
              }
            }
          }
        }
      }
    }
  `)
  const { logo } = data.alpackedJson.footerData
  const {
    allPrismicConsulting,
    allPrismicServicesPage,
    allPrismicSingleTechnologiesPage,
  } = data

  return (
    <Fragment>
      <FooterWrapper>
        <Container width="1220px">
          <Box className="row" {...row}>
            <Box className="col-one" {...colOne}>
              <Logo
                className="logo"
                href="/"
                logoSrc={logo.publicURL}
                title="Interior"
              />

              <Link to="/contacts/">
                <Text className="pagesLinks" content="Contacts" />
              </Link>
              <Careers>
                <Link to="/careers/" className="careers">
                  <Text className="pagesLinks" content="Careers" />
                </Link>
                <Link to="/careers/">
                  <Text className="hireLink" content="We are hiring!" />
                </Link>
              </Careers>
              <SocialList>
                {socialLinks.map(item => (
                  <li className={item.name} key={`link-key${item.id}`}>
                    <a
                      aria-label={item.name}
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.icon}
                    </a>
                  </li>
                ))}
              </SocialList>
            </Box>
            {/* End of logo column */}

            <Box className="col-two" {...colTwo}>
              <Box className="col" {...col}>
                <Heading className="widget_title" content="Services" />
                <List>
                  {allPrismicServicesPage.edges.map(item => (
                    <ListItem key={item.node.id}>
                      <Link to={`/services/${item.node.uid}/`}>
                        {item.node.data.title.text}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box className="col" {...col}>
                <Heading className="widget_title" content="Consulting" />
                <List>
                  {allPrismicConsulting.edges.map((item, index) => (
                    <ListItem key={item.node.id}>
                      <Link to={`/consulting/${item.node.uid}/`}>
                        {item.node.data.title.text}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box className="col" {...col}>
                <Heading className="widget_title" content="Technologies" />
                <List>
                  {allPrismicSingleTechnologiesPage.edges.map((item, index) => (
                    <ListItem key={item.node.id}>
                      <Link to={`/technologies/${item.node.uid}/`}>
                        {item.node.data.title.text}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
              {/* {menuWidgets.map(widget => (
                <Box
                  className="col"
                  {...col}
                  key={`footer_widget-key${widget.id}`}
                >
                  <Heading className="widget_title" content={widget.title} />
                  <List>
                    {widget.menu.map((item, index) => (
                      <ListItem key={index}>
                        <a href={item.link}>{item.text}</a>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))} */}
            </Box>
            {/* End of List column */}
          </Box>
          {/* End of widgets row */}
          <Box className="row copyright" {...row}>
            <Text className="text" content="© 2023 Alpacked LLC" />
            <div className="links">
              <Link to="/privacy-policy/">
                <Text content="Privacy Policy" />
              </Link>
              <Link to="/terms-of-use/">
                <Text content="Terms of Use" />
              </Link>
              <p>All rights reserved.</p>
              <div className="goodfirms">
                <img
                  style={{ width: 60, marginLeft: "16px" }}
                  src={logo.publicURL}
                  alt="logo"
                />
              </div>
            </div>
          </Box>
          {/* End of copyright row */}
        </Container>
      </FooterWrapper>
    </Fragment>
  )
}

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
}

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  // Footer col one style
  colOne: {
    width: ["100%", "100%", "100%", "20%"],
    mt: [0, "13px", "0"],
    mb: ["30px", "30px", 0, 0],
    mr: "5%",
  },
  // Footer col two style
  colTwo: {
    width: ["100%", "100%", "100%", "75%"],
    flexBox: true,
    justifyContent: "space-between",
    flexWrap: "wrap",
    ml: "auto",
  },
  // Footer col default style
  // col: {
  //   width: ["100%", "50%", "50%", "20%"],
  //   mr: ["0%","5%"],
  //   mb: "30px",
  // },
}

export default Footer
