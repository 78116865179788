import "rc-tabs/assets/index.css"

import React from "react"
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar"
import TabContent from "rc-tabs/lib/TabContent"
import Tabs, { TabPane } from "rc-tabs"
import { Icon } from "react-icons-kit"
import { Link } from "gatsby"
import { arrowRight } from "react-icons-kit/fa/arrowRight"

import Container from "../UI/Container"
import Text from "../Text"
import SectionWrapper, { SubmenuWrapper } from "./submenu.style"

const Submenu = ({ content, consulting }) => {
  const subMenuItems = [
    {
      title: "DevOps services",
      links: content.devopsSub.edges,
    },
    {
      title: "Cloud services",
      links: content.cloudSub.edges,
    },
  ]
  const subMenuItemsCons = [
    {
      title: "DevOps consulting",
      links: content.devOpsSubCons.edges,
    },
    {
      title: "Cloud consulting",
      links: content.cloudSubCons.edges,
    },
  ]
  return (
    <SectionWrapper>
      <Container width="1440px">
        {!consulting ? (
          <Tabs
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent animated={false} />}
            className="update-screen-tab"
          >
            {subMenuItems.map((item, index) => (
              <TabPane tab={<>{item.title}</>} key={index + 1}>
                <SubmenuWrapper>
                  {item.links.map((link, index) => {
                    return (
                      <Link to={`/services/${link.node.uid}/`} key={index}>
                        <Text content={link.node.data.title.text} />
                      </Link>
                    )
                  })}
                  <Link
                    to="/services/"
                    key={index}
                    className="see_all_container"
                  >
                    <Text content="SEE ALL SERVICES" className="see_all" />
                    <Icon icon={arrowRight} size={24} />
                  </Link>
                </SubmenuWrapper>
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <Tabs
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent animated={false} />}
            className="update-screen-tab"
          >
            {subMenuItemsCons.map((item, index) => (
              <TabPane tab={<>{item.title}</>} key={index + 1}>
                <SubmenuWrapper>
                  {item.links.map((link, index) => {
                    return (
                      <Link to={`/consulting/${link.node.uid}/`} key={index}>
                        <Text content={link.node.data.title.text} />
                      </Link>
                    )
                  })}
                  <Link
                    to="/consulting/"
                    key={index}
                    className="see_all_container"
                  >
                    <Text content="SEE ALL CONSULTINGS" className="see_all" />
                    <Icon icon={arrowRight} size={24} />
                  </Link>
                </SubmenuWrapper>
              </TabPane>
            ))}
          </Tabs>
        )}
      </Container>
    </SectionWrapper>
  )
}

export default Submenu
